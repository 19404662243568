import React, { Component, Fragment } from "react";
import "./../styles/Common.scss";

class Footer extends Component {

  componentDidMount = () => {
  };
  
  render() {
    return (
      <Fragment>
        <div id="footer">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                © DAIKIN {new Date().getFullYear()}. ALL RIGHTS RESERVED
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Footer;
