import { APIS, APP, LANG } from "./../constants";

export const ProductService = {
  get,
  post,
  find,
};

function get(data) {
  const requestOptions = {
    method: APP.REQUEST.GET,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
    },
    // body: {
    //   body: JSON.stringify(data),
    // }
  };
  return fetch(APIS.BASE_URL + APIS.GET_ALL_PRODUCTS, requestOptions).then(
    handleResponse
  );
}

function post(data) {
  const requestOptions = {
    method: APP.REQUEST.POST,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
    },
    body: JSON.stringify(data),
  };
  return fetch(APIS.BASE_URL + APIS.GET_ALL_PRODUCTS, requestOptions).then(
    handleResponse
  );
}

function find(productType, productId) {
  const requestOptions = {
    method: APP.REQUEST.POST,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
    },
  };
  var countryId =
    localStorage.getItem("countryId") !== null &&
    localStorage.getItem("countryId") !== ""
      ? localStorage.getItem("countryId")
      : 0;
  console.log('Req path: ' + APIS.BASE_URL +
  APIS.GET_PRODUCT_DETAILS +
  productType +
  "/" +
  productId +
  "/" +
  countryId);
  return fetch(
    APIS.BASE_URL +
      APIS.GET_PRODUCT_DETAILS +
      productType +
      "/" +
      productId +
      "/" +
      countryId,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  // document.getElementById("loader").style.display = "block";
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    // document.getElementById("loader").style.display = "none";
    if (!response.ok) {
      const error =
        LANG.APIERROR || (data && data.message) || response.statusText;
      return Promise.reject(new Error(error));
    }
    return data;
  });
}
