import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import "./../styles/Common.scss";
const $ = window.$;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  };

  render() {
    console.log(this.props);
    return (
      <Fragment>
        <div id="header">
          <Link to="/">
            <img
              src="./../../../img/logo.png"
              style={{ width: 200 }}
              className="img-fluid"
              alt=""
            />
          </Link>
        </div>
      </Fragment>
    );
  }
}

export default Header;
