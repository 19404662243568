import React, { Component, Fragment } from "react";
import HomeView from "../views/HomeView.js";
import Header from "../../common/views/Header.js";
import Footer from "../../common/views/Footer.js";
import Notify from "../../../helpers/Notify";
import { ProductService } from "./../../../services/product.service";
import { APP } from "./../../../constants";

class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      airPurifierProducts: [],
      wallMountProducts: [],
      concealedCeilingProducts: [],
      cassetteProducts: [],
      floorStandingProducts: [],
      ceilingMountedProducts: [],
      ceilingSuspendedProducts: [],
      rooftopProducts: [],
      vrvIndoorProducts: [],
      vrvOutdoorProducts: [],
      countries: [],
      noProducts: false,
    };
    this.getProducts = this.getProducts.bind(this);
  }

  componentDidMount = () => {
    let data = {
      countryId:
        localStorage.getItem("countryId") !== null
          ? localStorage.getItem("countryId")
          : "",
      productType: [],
      documentType: [],
      searchText: "",
    };
    this.getProducts(data);
    document.title = 'Find My Product | Daikin MEA';
  };

  componentDidUpdate = () => {};

  getProducts = (data) => {
    ProductService.post(data).then(
      (response) => {
        if (response.status_code === APP.CODE.SUCCESS) {
          // console.log(response.wall_mount_products);
          this.setState({
            airPurifierProducts: response.air_purifier_products,
            wallMountProducts: response.wall_mount_products,
            concealedCeilingProducts: response.concealed_ceiling_products,
            cassetteProducts: response.cassette_products,
            floorStandingProducts: response.floor_standing_products,
            ceilingMountedProducts: response.ceiling_mounted_products,
            ceilingSuspendedProducts: response.ceiling_suspended_products,
            rooftopProducts: response.rooftop_products,
            vrvIndoorProducts: response.vrv_indoor_products,
            vrvOutdoorProducts: response.vrv_outdoor_products,
            countries: response.countries,
            noProducts: response.air_purifier_products.length === 0 && response.wall_mount_products.length === 0 ? true: false
          });
        } else {
          Notify.error(response.statusInfo.errorMessage);
        }
      },
      (error) => {
        error.statusInfo
          ? Notify.error(error.statusInfo.errorMessage)
          : Notify.error(error.message);
      }
    );
  };

  filterProducts = (data) => {
    this.getProducts(data);
    console.log(data);
  };

  render() {
    return (
      <Fragment>
        <Header
          {...this.props}
          {...this.state}
        />
        <HomeView {...this.props} {...this.state} filterProducts={this.filterProducts} />
        <Footer />
      </Fragment>
    );
  }
}

export default HomeContainer;

