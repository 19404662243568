import React, { Component, Fragment } from "react";
import Header from "../../common/views/Header.js";
import Footer from "../../common/views/Footer.js";
import ProductView from "../views/ProductView.js";
import Notify from "../../../helpers/Notify";
import { ProductService } from "./../../../services/product.service";
import { APP } from "./../../../constants";

class ProductContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      productDetails: [],
      productCatagory: '',
      productSubCatagory: '',
      featuredImage: [],
      mediaGallery: [],
      mediaImageLink: [],
      mediaVideoLink: [],
      productImages: [],
      catalogDocument: [],
      iomDocument: [],
      dataBookDocument: [],
      serviceManualDocument: [],
      certificateDocument: [],
      specificationGuide: [],
      airPurifierProducts: [],
      wallMountProducts: [],
      concealedCeilingProducts: [],
      cassetteProducts: [],
      floorStandingProducts: [],
      ceilingMountedProducts: [],
      rooftopProducts: [],
      vrvIndoorProducts: [],
      vrvOutdoorProducts: [],
      vrvApplicableIndoors: [],
      vrvApplicableOutdoors: [],
      countries: [],
      loading: true,
    };
    this.getProductDetails = this.getProductDetails.bind(this);
    this.getProducts = this.getProducts.bind(this);
  }

  componentDidMount = () => {
    let data = {
      // countryId: localStorage.getItem('countryId') !== null ? localStorage.getItem('countryId'): '',
      // productType: localStorage.getItem('productType') !== null ? localStorage.getItem('productType'): '',
      // documentType: localStorage.getItem('documentType') !== null ? localStorage.getItem('documentType'): '',
      countryId: [],
      productType: [],
      documentType: [],
      searchText: '',
    };
    this.getProducts(data);
    this.getProductDetails(
      this.props.match.params.productType,
      this.props.match.params.productId
    );
  };

  // componentDidUpdate = () => {
  //   if (this.props.match.params.id) {
  //     this.getProductDetails(
  //       this.props.match.params.productType,
  //       this.props.match.params.productId
  //     );
  //   }
  // };

  getProductDetails = (productType, productId) => {
    ProductService.find(productType, productId).then(
      (response) => {
        if (response.status_code === APP.CODE.SUCCESS) {
          // console.log(response);
          // console.log(response.media_image_link);
          let mediaImageLinks = response.media_image_link.length > 0 ? response.media_image_link[0]['meta_value'].split(','): [];
          let videoLinks = response.media_video_link.length > 0 ? response.media_video_link[0]['meta_value'].split(','): [];
          this.setState({
            products: response.products,
            productDetails: response.product_details,
            productCatagory: response.product_catagory,
            productSubCatagory: response.product_sub_catagory,
            featuredImage: response.featured_image,
            mediaGallery: response.media_gallery,
            mediaImageLink: mediaImageLinks,
            mediaVideoLink: videoLinks,
            productImages: response.product_images,
            catalogDocument: response.catalog_document,
            iomDocument: response.iom_document,
            dataBookDocument: response.data_book_document,
            serviceManualDocument: response.service_manual_document,
            certificateDocument: response.certificate_document,
            specificationGuide:response.specification_guide,
            vrvApplicableIndoors:response.applicable_indoors,
            vrvApplicableOutdoors: response.applicable_outdoors,
            countries: response.countries,
            loading: false
          });
        } else {
          Notify.error(response.statusInfo.errorMessage);
        }
      },
      (error) => {
        error.statusInfo
          ? Notify.error(error.statusInfo.errorMessage)
          : Notify.error(error.message);
      }
    );
  };

  getProducts = (data) => {
    ProductService.post(data).then(
      (response) => {
        if (response.status_code === APP.CODE.SUCCESS) {
          // console.log(response.wall_mount_products);
          this.setState({
            airPurifierProducts: response.air_purifier_products,
            wallMountProducts: response.wall_mount_products,
            concealedCeilingProducts: response.concealed_ceiling_products,
            cassetteProducts: response.cassette_products,
            floorStandingProducts: response.floor_standing_products,
            ceilingMountedProducts: response.ceiling_mounted_products,
            rooftopProducts: response.rooftop_products,
            vrvIndoorProducts: response.vrv_indoor_products,
            vrvOutdoorProducts: response.vrv_outdoor_products,
            countries: response.countries,
          });
        } else {
          Notify.error(response.statusInfo.errorMessage);
        }
      },
      (error) => {
        error.statusInfo
          ? Notify.error(error.statusInfo.errorMessage)
          : Notify.error(error.message);
      }
    );
  }

  filterProducts = (data) => {
    this.getProductDetails(
      this.props.match.params.productType,
      this.props.match.params.productId
    );
  };

  render() {
    return (
      <Fragment>
        <Header
          {...this.props}
          {...this.state}
          filterProducts={this.filterProducts}
        />
        <ProductView {...this.props} {...this.state} updateParent={this.getProductDetails}/>
        <Footer />
      </Fragment>
    );
  }
}

export default ProductContainer;
