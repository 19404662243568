import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL, WHERE_TO_BUY } from "../../../constants";
import "./../styles/Product.scss";

class ProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productImage: "",
      viewLoaded: false,
      productImages: [],
    };
    this.openInNewTab = this.openInNewTab.bind(this);
    this.updateProductImage = this.updateProductImage.bind(this);
    this.initilizeProductImage = this.initilizeProductImage.bind(this);
    this.getBreadCrumb = this.getBreadCrumb.bind(this);
    this.manageNavigation = this.manageNavigation.bind(this);
    this.toggleDetails = this.toggleDetails.bind(this);
  }

  componentDidMount = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.productDetails !== prevProps.productDetails) {
      if (this.props.productImages.length > 0) {
        this.setState({
          productImage: this.props.productImages[0].meta_value,
          viewLoaded: true,
        });
      }
      let title = '';
      if (localStorage.getItem("selectedCountry") !== null && localStorage.getItem("selectedCountry").toLowerCase() !== 'select country') {
        title += `${this.props.productDetails.length > 0 ? this.props.productDetails[0]["product_series_name"] : ''} | ${localStorage.getItem("selectedCountry")} | Find My Product | Daikin MEA`;
      } else {
        title += `${this.props.productDetails.length > 0 ? this.props.productDetails[0]["product_series_name"] : ''} | Find My Product | Daikin MEA`;
      }
      document.title = title;
    }
  };

  capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      });
    return vars;
  }

  initilizeProductImage = () => {
    let image = this.getDecodedString(this.getUrlVars()["featured_image"]);
    if (this.props.productImages) {
      this.setState({
        productImages: [{ 'meta_key': 'product_image', 'meta_value': image }].concat(this.props.productImages),
        productImage: image,
        viewLoaded: true,
      });
    }
    console.log('PI', this.state.productImages);
  };

  updateProductImage = (image) => {
    this.setState({
      productImage: image,
    });
  };

  openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  getBreadCrumb = (name) => {
    name = name.replace("-products", "");
    name = name.replace("-", " ");
    // name = name.replace("mount", "mounted");
    name = name.replace("vrv", "VRV");
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  manageNavigation = (index) => {
    if (this.props.mediaImageLink[index] !== null) {
      if (this.props.mediaImageLink[index] !== '') {
        window.location = this.props.mediaImageLink[index].trim();
      }
    }
  }

  getDecodedString(string) {
    return atob(string);
  }

  toggleDetails = (event) => {
    const detailsRow = event.currentTarget.nextElementSibling;
    const icon = event.currentTarget.querySelector(".toggle-icon");

    if (detailsRow.style.display === "table-row") {
      detailsRow.style.display = "none";
      icon.classList.remove("active"); // Remove .active class
    } else {
      detailsRow.style.display = "table-row";
      icon.classList.add("active"); // Add .active class
    }
  }

  render() {
    // console.log(this.props.products);
    if (!this.state.viewLoaded) {
      setTimeout(() => this.initilizeProductImage(), 300);
    }
    return (
      <Fragment>
        <div id="breadcrumb">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Link style={{ cursor: "pointer" }} to="/"><b>Home</b></Link> / {" "}
                {/* <Link to={"/products/" + this.props.match.params.productType}> */}
                <span>
                  {this.getBreadCrumb(this.props.match.params.productType)}
                </span>{" "}
                {/* </Link>{" "} */}
                /{" "}
                <span>
                  {this.props.productDetails[0] != null
                    ? this.props.productDetails[0]["product_series_name"]
                    : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="main-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                {this.state.productImages.length > 0 && (
                  <div id="slideshow">
                    <div id="selected">
                      {this.state.productImage !== "" && (
                        <Link to="#">
                          <img
                            className="img-fluid"
                            src={`${IMAGE_BASE_URL}/storage${this.state.productImage}`}
                            alt=""
                          />
                        </Link>
                      )}
                      {/* <img src="./../../../img/ac-slider.png" className="img-fluid" alt="" /> */}
                    </div>
                    <div id="slides">
                      {this.state.productImages.map((image, i) => {
                        return (
                          <span key={i} style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              this.updateProductImage(image.meta_value)
                            } className={
                              this.state.productImage === image.meta_value
                                ? "prod-lst-img  pro-back thumb bordered-img"
                                : "prod-lst-img  pro-back thumb"
                            }>
                            <img src={`${IMAGE_BASE_URL}/storage${image.meta_value}`} alt="" />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
                {(this.props.mediaVideoLink.length > 0 || this.props.mediaGallery.length > 0) && (
                  <div className="row" id="videos-container">
                    <div className="col-md-12">
                      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                          <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner">
                          {this.props.mediaGallery.map((image, i) => {
                            return (
                              <div
                                key={'image-' + i}
                                onClick={e => this.manageNavigation(i)}
                                className={
                                  i === 0
                                    ? "carousel-item active"
                                    : "carousel-item"
                                }
                              >
                                <img
                                  height="200px"
                                  className="d-block w-100"
                                  src={`${IMAGE_BASE_URL}/storage${image.meta_value}`}
                                  alt={image.meta_key}
                                />
                              </div>
                            );
                          })}
                          {this.props.mediaVideoLink.map((video, j) => {
                            return (
                              <div
                                key={'video-' + j}

                                className={
                                  "active carousel-item "
                                }
                              >
                                <iframe
                                  height="200px"
                                  title={"caurosel " + j}
                                  className="d-block w-100 video"
                                  src={video.trim() + '?controls=1'}
                                  alt={"slide" + j}
                                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen
                                >
                                </iframe>
                              </div>
                            );
                          })}
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-5">
                <div id="prod-details">
                  <div className="row">
                    <div className="col-12">
                      <h1>{this.props.productDetails[0] != null
                        ? this.props.productDetails[0]["product_series_name"] +
                        " Series"
                        : ""}</h1>
                      <p>
                        {this.props.productDetails[0] != null
                          ? this.props.productDetails[0]["description"]
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="specs">
                        {this.props.match.params.productType !==
                          "air-purifier-products" && (
                            <Fragment>
                              {this.props.productDetails[0] !==
                                null && (
                                  <div className="spec-item">
                                    <span>
                                      <img
                                        src={`/img/detail-icons/refrigerant-icon.png`}
                                        alt=""
                                      />
                                    </span>
                                    <span>
                                      <h4>Refrigerant</h4>
                                      <h6>{this.props.productDetails[0] != null
                                        ? this.props.productDetails[0][
                                        "refrigerant"
                                        ]
                                        : ""}</h6>
                                    </span>
                                  </div>
                                )}
                              {this.props.match.params.productType !==
                                "vrv-indoors" && (
                                  <div className="spec-item">
                                    <span>
                                      <img
                                        src={`/img/detail-icons/mode-icon.png`}
                                        alt=""
                                      />
                                    </span>
                                    <span>
                                      <h4>Mode</h4>
                                      <h6>{this.props.productDetails[0] != null
                                        ? this.capitalize(
                                          this.props.productDetails[0][
                                            "mode"
                                          ].replace("-", " ")
                                        )
                                        : ""}</h6>
                                    </span>
                                  </div>
                                )}
                              <div className="spec-item">
                                <span>
                                  <img
                                    src={`/img/detail-icons/frequency-iocn.png`}
                                    alt=""
                                  />
                                </span>
                                <span>
                                  <h4>Frequency</h4>
                                  <h6>{this.props.productDetails[0] != null
                                    ? this.props.productDetails[0][
                                      "frequency"
                                    ] === "50-60-hz"
                                      ? "50/60 Hz"
                                      : this.props.productDetails[0][
                                        "frequency"
                                      ] === "50-hz"
                                        ? "50Hz"
                                        : this.props.productDetails[0][
                                          "frequency"
                                        ] === "60-hz"
                                          ? "60Hz"
                                          : ""
                                    : ""}</h6>
                                </span>
                              </div>
                            </Fragment>
                          )}
                        {this.props.productDetails[0] !==
                          null && (
                            <div className="spec-item">
                              <span>
                                <img
                                  src={`/img/detail-icons/technology-icon.png`}
                                  alt=""
                                />
                              </span>
                              <span>
                                <h4>Technology</h4>
                                <h6>{this.props.productDetails[0] != null
                                  ? this.props.productDetails[0]["technology"]
                                  : ""}</h6>
                              </span>
                            </div>
                          )}
                        {(this.props.match.params.productType ===
                          "air-purifier-products" || this.props.match.params.productType ===
                          "wall-mount-products" || this.props.match.params.productType ===
                          "cassette" || this.props.match.params.productType ===
                          "floor-standing" || this.props.match.params.productType ===
                          "ceiling-mounted" || this.props.match.params.productType ===
                          "rooftop") && this.props.productDetails[0] != null && (
                            <div className="spec-item">
                              <span>
                                <img
                                  src={`/img/detail-icons/climate-icon.png`}
                                  alt=""
                                />
                              </span>
                              <span>
                                <h4>Climate</h4>
                                <h6>{this.props.productDetails[0]["ambient"] != null
                                  ? this.props.productDetails[0]["ambient"] ===
                                    "high-amb-stand-amb"
                                    ? "High Ambient/Standard Ambient"
                                    : this.capitalize(
                                      this.props.productDetails[0][
                                        "ambient"
                                      ].replace("-", " ")
                                    )
                                  : ""}</h6>
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <a target="_blank" className="cta-main me-3 mt-4" href="https://www.daikinmea.com/en_us/contact.html"> CONTACT US </a>
                  <Link
                    target="_blank"
                    to={`${WHERE_TO_BUY}`}
                  >
                    <a target="_blank" className="cta-main" href="https://www.daikinmea.com/en_us/dmea-sales-network.html">Where to buy</a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row pb-5 mb-5 mt-4">
              <div className="col-12">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Product Specifications
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Documentation
                    </button>
                  </li>
                </ul>

                <div className="tab-content tab-content-docs">
                  <div
                    className="tab-pane active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >

                    <div className="table-responsive d-none d-lg-block">
                      {this.props.match.params.productType === "air-purifier-products" && (
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" colSpan="1">Product Name</th>
                              <th scope="col" colSpan="1">Coverage Area (Sqm) </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.products.map((product, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    {product.product_name !== null &&
                                      product.product_name !== ""
                                      ? product.product_name
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {product.coverage_area !== null &&
                                      product.coverage_area !== ""
                                      ? product.coverage_area
                                      : "N/A"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                      {(this.props.match.params.productType ===
                        "wall-mount-products" ||
                        this.props.match.params.productType ===
                        "concealed-ceiling" ||
                        this.props.match.params.productType ===
                        "ceiling-mounted" ||
                        this.props.match.params.productType === "cassette" ||
                        this.props.match.params.productType ===
                        "floor-standing" || this.props.match.params.productType === "ceiling-suspended") && (
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" colSpan="2">Product Name</th>
                                <th scope="col" colSpan="2">Nominal Cooling Capacity </th>
                                <th scope="col" colSpan="4">Nominal Heating Capacity </th>
                              </tr>
                              <tr>
                                <th scope="col">Indoor</th>
                                <th scope="col">Outdoor </th>
                                <th scope="col">Nom</th>
                                <th scope="col">Max</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Max</th>
                                <th scope="col">EER Nominal</th>
                                <th scope="col">COP Nominal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.products.map((product, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      {product.indoor_product_name !== null &&
                                        product.indoor_product_name !== ""
                                        ? product.indoor_product_name
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {product.outdoor_product_name !== null &&
                                        product.outdoor_product_name !== ""
                                        ? product.outdoor_product_name
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {product.cooling_cap_Nominal !== null &&
                                        product.cooling_cap_Nominal !== ""
                                        ? product.cooling_cap_nom
                                        : "N/A"}
                                    </td>
                                    <td className="tbl-brd1">
                                      {product.cooling_cap_max !== null &&
                                        product.cooling_cap_max !== ""
                                        ? product.cooling_cap_max
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {product.heating_cap_Nominal !== null &&
                                        product.heating_cap_Nominal !== ""
                                        ? product.heating_cap_nom
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {product.heating_cap_max !== null &&
                                        product.heating_cap_max !== ""
                                        ? product.heating_cap_max
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {product.eer_nominal !== null &&
                                        product.eer_nominal !== ""
                                        ? product.eer_nominal
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {product.cop_nominal !== null &&
                                        product.cop_nominal !== ""
                                        ? product.cop_nominal
                                        : "N/A"}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}

                      {(this.props.match.params.productType === "rooftops") && (
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" colSpan="1">Product Name</th>
                              <th scope="col" colSpan="1">Nominal Cooling Capacity </th>
                              <th scope="col" colSpan="1">Nominal Heating Capacity </th>
                              <th scope="col" colSpan="1">EER Nominal </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.products.map((product, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    {product.product_name !== null &&
                                      product.product_name !== ""
                                      ? product.product_name
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {product.cooling_cap_nom !== null &&
                                      product.cooling_cap_nom !== ""
                                      ? product.cooling_cap_nom
                                      : "N/A"}
                                  </td>

                                  <td>
                                    {product.heating_cap_nom !== null &&
                                      product.heating_cap_nom !== ""
                                      ? product.heating_cap_nom
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {product.eer_nominal !== null &&
                                      product.eer_nominal !== ""
                                      ? product.eer_nominal
                                      : "N/A"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}

                      {(this.props.match.params.productType === "vrv-indoors" ||
                        this.props.match.params.productType ===
                        "vrv-outdoors") && (
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" colSpan="1">Product Name</th>
                                <th scope="col" colSpan="1">Nominal Cooling Capacity </th>
                                <th scope="col" colSpan="1">Nominal Heating Capacity </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.products.map((product, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      {product.product_name !== null &&
                                        product.product_name !== ""
                                        ? product.product_name
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {product.cooling_cap_Nominal !== null &&
                                        product.cooling_cap_Nominal !== ""
                                        ? product.cooling_cap_nom
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {product.heating_cap_Nominal !== null &&
                                        product.heating_cap_Nominal !== ""
                                        ? product.heating_cap_nom
                                        : "N/A"}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                    </div>

                    <div className="table-responsive d-block d-lg-none">
                      {this.props.match.params.productType === "air-purifier-products" && (
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Coverage Area (Sqm) </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.products.map((product, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    {product.product_name !== null &&
                                      product.product_name !== ""
                                      ? product.product_name
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {product.coverage_area !== null &&
                                      product.coverage_area !== ""
                                      ? product.coverage_area
                                      : "N/A"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}

                      {(this.props.match.params.productType ===
                        "wall-mount-products" ||
                        this.props.match.params.productType ===
                        "concealed-ceiling" ||
                        this.props.match.params.productType ===
                        "ceiling-mounted" ||
                        this.props.match.params.productType === "cassette" ||
                        this.props.match.params.productType ===
                        "floor-standing" || this.props.match.params.productType === "ceiling-suspended") && (
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" colSpan="2">Product Name</th>
                              </tr>
                              <tr>
                                <th scope="col">Indoor</th>
                                <th scope="col">Outdoor </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.products.map((product, i) => {
                                return (
                                  <Fragment key={i}>
                                    <tr className="main-row" onClick={(e) => this.toggleDetails(e)}>
                                      <td><span className="toggle-icon"></span>
                                        {product.indoor_product_name !== null &&
                                          product.indoor_product_name !== ""
                                          ? product.indoor_product_name
                                          : "N/A"}
                                      </td>
                                      <td>{product.outdoor_product_name !== null &&
                                        product.outdoor_product_name !== ""
                                        ? product.outdoor_product_name
                                        : "N/A"}</td>
                                    </tr>
                                    <tr className="details-row">
                                      <td colspan="2">
                                        <div className="details">
                                          <h5 className="details-title">Nom Cooling Capacity</h5>
                                          <p><strong>Nom</strong>:
                                            {product.cooling_cap_Nominal !== null &&
                                              product.cooling_cap_Nominal !== ""
                                              ? product.cooling_cap_nom
                                              : "N/A"}
                                          </p>
                                          <p><strong>Max</strong>:
                                            {product.cooling_cap_max !== null &&
                                              product.cooling_cap_max !== ""
                                              ? product.cooling_cap_max
                                              : "N/A"}
                                          </p>
                                          <hr />
                                          <h5 className="details-title">Nom Heating Capacity</h5>
                                          <p><strong>Nom</strong>:
                                            {product.heating_cap_Nominal !== null &&
                                              product.heating_cap_Nominal !== ""
                                              ? product.heating_cap_nom
                                              : "N/A"}
                                          </p>
                                          <p><strong>Max</strong>:
                                            {product.heating_cap_max !== null &&
                                              product.heating_cap_max !== ""
                                              ? product.heating_cap_max
                                              : "N/A"}
                                          </p>
                                          <p><strong>EER Nominal</strong>:
                                            {product.eer_nominal !== null &&
                                              product.eer_nominal !== ""
                                              ? product.eer_nominal
                                              : "N/A"}
                                          </p>
                                          <p><strong>COP Nominal</strong>:
                                            {product.cop_nominal !== null &&
                                              product.cop_nominal !== ""
                                              ? product.cop_nominal
                                              : "N/A"}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  </Fragment>
                                )
                              })}
                            </tbody>
                          </table>
                        )}

                        {this.props.match.params.productType === "rooftops" && (
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Nominal Cooling Capacity </th>
                              <th>Nominal Heating Capacity </th>
                              <th>EER Nominal </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.products.map((product, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    {product.product_name !== null &&
                                      product.product_name !== ""
                                      ? product.product_name
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {product.cooling_cap_nom !== null &&
                                      product.cooling_cap_nom !== ""
                                      ? product.cooling_cap_nom
                                      : "N/A"}
                                  </td>

                                  <td>
                                    {product.heating_cap_nom !== null &&
                                      product.heating_cap_nom !== ""
                                      ? product.heating_cap_nom
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {product.eer_nominal !== null &&
                                      product.eer_nominal !== ""
                                      ? product.eer_nominal
                                      : "N/A"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}

                      {(this.props.match.params.productType === "vrv-indoors" ||
                        this.props.match.params.productType ===
                        "vrv-outdoors") && (
                          <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Nominal Cooling Capacity </th>
                              <th>Nominal Heating Capacity </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.products.map((product, i) => {
                              return (
                                <tr key={i}>
                                    <td>
                                      {product.product_name !== null &&
                                        product.product_name !== ""
                                        ? product.product_name
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {product.cooling_cap_Nominal !== null &&
                                        product.cooling_cap_Nominal !== ""
                                        ? product.cooling_cap_nom
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {product.heating_cap_Nominal !== null &&
                                        product.heating_cap_Nominal !== ""
                                        ? product.heating_cap_nom
                                        : "N/A"}
                                    </td>
                                  </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}

                      {/* <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th colspan="2">M Product Name</th>
                          </tr>
                          <tr>
                            <th>Indoor</th>
                            <th>Outdoor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="main-row" onClick={(e) => this.toggleDetails(e)}>
                            <td><span className="toggle-icon"></span> FTKM18PVMK</td>
                            <td>RKM18PVMK</td>
                          </tr>
                          <tr className="details-row">
                            <td colspan="2">
                              <div className="details">
                                <h5 className="details-title">Nom Cooling Capacity</h5>
                                <p><strong>Nom</strong>: 18,000</p>
                                <p><strong>Max</strong>: 18,000</p>
                                <hr />
                                <h5 className="details-title">Nom Heating Capacity</h5>
                                <p><strong>Nom</strong>: N/A</p>
                                <p><strong>Max</strong>: N/A</p>
                                <p><strong>EER Nominal</strong>: 13.33</p>
                                <p><strong>COP Nominal</strong>: N/A</p>
                              </div>
                            </td>
                          </tr>
                          <tr className="main-row" onClick={(e) => this.toggleDetails(e)}>
                            <td><span className="toggle-icon"></span> FTKM18PVMK</td>
                            <td>RKM18PVMK</td>
                          </tr>
                          <tr className="details-row">
                            <td colspan="2">
                              <div className="details">
                                <h5 className="details-title">Nom Cooling Capacity</h5>
                                <p><strong>Nom</strong>: 18,000</p>
                                <p><strong>Max</strong>: 18,000</p>
                                <hr />
                                <h5 className="details-title">Nom Heating Capacity</h5>
                                <p><strong>Nom</strong>: N/A</p>
                                <p><strong>Max</strong>: N/A</p>
                                <p><strong>EER Nominal</strong>: 13.33</p>
                                <p><strong>COP Nominal</strong>: N/A</p>
                              </div>
                            </td>
                          </tr>
                          <tr className="main-row" onClick={(e) => this.toggleDetails(e)}>
                            <td><span className="toggle-icon"></span> FTKM18PVMK</td>
                            <td>RKM18PVMK</td>
                          </tr>
                          <tr className="details-row">
                            <td colspan="2">
                              <div className="details">
                                <h5 className="details-title">Nom Cooling Capacity</h5>
                                <p><strong>Nom</strong>: 18,000</p>
                                <p><strong>Max</strong>: 18,000</p>
                                <hr />
                                <h5 className="details-title">Nom Heating Capacity</h5>
                                <p><strong>Nom</strong>: N/A</p>
                                <p><strong>Max</strong>: N/A</p>
                                <p><strong>EER Nominal</strong>: 13.33</p>
                                <p><strong>COP Nominal</strong>: N/A</p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table> */}
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    {this.props.catalogDocument.length > 0 && (
                      <div className="doc-row">
                        <h6>CATALOGUE</h6>
                        <div className="doc-items">
                          {this.props.catalogDocument.map((item, i) => {
                            return (
                              <button onClick={(e) =>
                                this.openInNewTab(item.document_url)
                              }
                                key={i}> {item.document_name} </button>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {this.props.iomDocument.length > 0 && (
                      <div className="doc-row">
                        <h6>INSTALLATION AND OPERATION MANUAL</h6>
                        <div className="doc-items">
                          {this.props.iomDocument.map((item, i) => {
                            return (
                              <button onClick={(e) =>
                                this.openInNewTab(item.document_url)
                              }
                                key={i}> {item.document_name} </button>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {this.props.dataBookDocument.length > 0 && (
                      <div className="doc-row">
                        <h6>DATA BOOK  {" "}<i className="fa fa-info-circle"></i>{" "}
                          <small>
                            Available only for Daikin ID users, if you don't have an account, please contact our sales team
                          </small></h6>
                        <div className="doc-items">
                          {this.props.dataBookDocument.map((item, i) => {
                            return (
                              <button onClick={(e) =>
                                this.openInNewTab(item.document_url)
                              }
                                key={i}> {item.document_name} </button>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {this.props.serviceManualDocument.length > 0 && (
                      <div className="doc-row">
                        <h6>SERVICE MANUAL</h6>
                        <div className="doc-items">
                          {this.props.serviceManualDocument.map((item, i) => {
                            return (
                              <button onClick={(e) =>
                                this.openInNewTab(item.document_url)
                              }
                                key={i}> {item.document_name} </button>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {this.props.certificateDocument.length > 0 && (
                      <div className="doc-row">
                        <h6>CERTIFICATES</h6>
                        <div className="doc-items">
                          {this.props.certificateDocument.map((item, i) => {
                            return (
                              <button onClick={(e) =>
                                this.openInNewTab(item.document_url)
                              }
                                key={i}> {item.document_name} </button>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ProductView;
